












import Component, {mixins} from 'vue-class-component';
import { placeModule, paymentModule } from '@/store';
import ThumbnailList from '@/components/list/ThumbnailList.vue';
import MainInfo from '@/components/deposit/MainInfo.vue';
import Payment from '@/components/deposit/Payment.vue';
import dataFormat from '@/shared/utils/dataFormat';
import MainFooter from '@/components/layout/MainFooter.vue';
import { EventBus } from '@/config/config';
import { Prop, Watch } from 'vue-property-decorator';

@Component({ components: { ThumbnailList, MainInfo, Payment, MainFooter } })
export default class Deposit extends mixins(dataFormat) {
  @Prop()
  public selectLang!: any;

  public payTypes: any = [];

  public beforeRouteEnter(to, from, next) {
    next((vm) => {
      paymentModule.fetchReservationSetting(to.params.reservationId).then((res) => {
        if (res.code === 20000) {
          // 이미 결제가 완료됐으면 결과 페이지로 이동
          if (
            res.data.pay_status !== 'wait' ||
            res.data.reserv_status === 'canceled'
          ) {
            if (to.fullPath.includes('lang=en')) {
              vm.$router.push({ path: `result/${to.params.reservationId}?lang=en` })
            } else {
              vm.$router.push({name: 'DepositResult', params: { reservationId: to.params.reservationId }});
            }
            return false;
          }
          // 매장 정보 조회
          placeModule.fetchPlaceInfoData(res.data.restaurant.poing_place_id);
          // 매장 이미지 조회
          placeModule.fetchPlaceImage(res.data.restaurant.poing_place_id);
        }
      });
      if (to.query.message) {
        vm.$q.notify(to.query.message);
      }
      paymentModule
        .fetchPayTypes(to.params.reservationId)
        .then((res) => {
          console.log(res.data);
          const payList = res.data;
          vm.payTypes = payList;
          if (
            localStorage.getItem('OS_DEPOSIT_LANG') &&
            localStorage.getItem('OS_DEPOSIT_LANG') !== 'ko'
          ) {
            const re = payList;
            let target = payList[5];
            let target2 = payList[6];
            re.splice(5, 2);
            re.unshift(target2);
            re.unshift(target);
            vm.payTypes = re;
          }
        });
    })
  }

  public created() {
    // EventBus.$on('lang', (data: any) => {
    //   this.selectLang = data;
    // });
    // if (!location.pathname.includes('/result')) {
    //   const reservationId = location.pathname.substring(1);
    //   paymentModule.fetchReservationSetting(reservationId).then((res) => {
    //     if (res.code === 20000) {
    //       // 이미 결제가 완료됐으면 결과 페이지로 이동
    //       if (this.reservation.pay_status !== 'wait') {
    //         this.$router.push({name: 'DepositResult', params: { reservationId: reservationId }})
    //         return false;
    //       }
    //       // 매장 정보 조회
    //       placeModule.fetchPlaceInfoData(this.reservation.restaurant.poing_place_id);
    //       // 매장 이미지 조회
    //       placeModule.fetchPlaceImage(this.reservation.restaurant.poing_place_id);
    //     }
    //   });
    //   paymentModule.fetchPayTypes(reservationId);
    // }
  }

  @Watch('selectLang')
  public selectLangUpdate() {
    const id = location.pathname.substr(1);
    this.payTypes = [];
    paymentModule
      .fetchPayTypes(id)
      .then((res) => {
        this.payTypes = res.data;
        if (
          localStorage.getItem('OS_DEPOSIT_LANG') &&
          localStorage.getItem('OS_DEPOSIT_LANG') !== 'ko'
        ) {
          const re = this.payTypes;
          let target = this.payTypes[5];
          let target2 = this.payTypes[6];
          re.splice(5, 2);
          re.unshift(target2);
          re.unshift(target);
          this.payTypes = re;
        } else if (localStorage.getItem('OS_DEPOSIT_LANG') === 'ko') {
          this.payTypes = res.data;
        }
      });
  }

  // 결제 기한
  get period() {
    let date: string = '';
    for (const pay of this.reservation.deposit) {
      if (!pay.paid_at) {
        date = pay.payment_limit_at;
      }
    }
    return date;
  }

  get placeInfoData() {
    return placeModule.placeInfoData;
  }

  get placeImage() {
    return placeModule.placeImage;
  }

  get reservation(): any {
    return paymentModule.getReservationData;
  }

  get payTypesData() {
    return paymentModule.getPayTypes;
  }
}






































































































































































import Component, {mixins} from 'vue-class-component';
import dataFormat from '@/shared/utils/dataFormat';
import PrivacyPopup from '@/components/popup/PrivacyPopup.vue';
import PaymentInfo from '@/components/deposit/PaymentInfo.vue';
import {Prop, Watch} from 'vue-property-decorator';
import {paymentModule} from '@/store';

@Component({ components: { PrivacyPopup, PaymentInfo } })
export default class Payment extends mixins(dataFormat) {
  @Prop({
    required: true
  })
  public reservation!: any;

  @Prop({
    required: true
  })
  public payTypeList!: any;

  @Prop()
  public selectLang!: any;

  public isLoading: boolean = false;
  public privacyType: string = '';
  public cardNo: string[] = ['', '', '', ''];
  public bizNo: string[] = ['', '', ''];
  public cardData: any = {
    pg_type: 'direct_pay',
    card_type: 'P',
    card_number: '',
    exp_year: '',
    exp_month: '',
    card_pw: '',
    id_no: '',
    platform: 'deposit_mweb'
  };
  public agree = {
    fee: false,
    use: false,
    thirdParty: false
  };
  public email: string = this.reservation.deposit ?
    this.reservation.deposit[this.reservation.deposit.length - 1].notice_email : '';

  public mounted() {
    this.initData();
  }

  public initData() {
    this.cardNo = ['', '', '', ''];
    this.bizNo = ['', '', ''];
    this.cardData = {
      pg_type: 'direct_pay',
      card_type: 'P',
      card_number: '',
      exp_year: '',
      exp_month: '',
      card_pw: '',
      id_no: '',
      platform: 'deposit_mweb'
    };
    if (this.selectLang !== 'ko') {
      this.cardData.pg_type = 'paypal';
    } else {
      this.cardData.pg_type = 'direct_pay';
    }
    setTimeout(() => {
      this.email = this.reservation.deposit ?
      this.reservation.deposit[this.reservation.deposit.length - 1].notice_email : '';
    }, 500);
  }

  public numberInput(e) {
    e = (e) ? e : window.event;
    const charCode = (e.which) ? e.which : e.keyCode;
    if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
      e.preventDefault();
    } else {
      return true;
    }
  }

  // 결제 params 설정
  public paymentDataSetting() {
    if (!this.paymentValidate) {
      if (this.cardData.pg_type === 'direct_pay') {
        this.cardData.card_number = [this.cardNo[0], this.cardNo[1], this.cardNo[2], this.cardNo[3]].join('');
        if (this.cardData.card_type === 'C') {
          this.cardData.id_no = [this.bizNo[0], this.bizNo[1], this.bizNo[2]].join('');
        }
        this.cardData.price = this.totalPrice.price;
        this.buy(this.cardData);
      } else {
        const params = {
          platform: 'deposit_mweb',
          pg_type: this.cardData.pg_type,
          price: this.cardData.pg_type === 'paypal' ? this.totalPrice.price_usd : this.totalPrice.price,
          email: this.email
        };
        this.buy(params);
      }
    } else {
      if (this.cardData.pg_type === 'paymentwall') {
        const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (!this.email.match(mailformat)) {
          if (this.email === '') {
            this.$q.notify('이메일을 입력해주세요.');
            return;
          }
          this.$q.notify('이메일을 확인해주세요.');
        }
      }
    }
  }

  // 결제 api 통신
  public buy(params) {
    this.isLoading = true;
    params.reservation_id = this.$route.params.reservationId;
    paymentModule.payment(params).then((res) => {
      this.isLoading = false;
      if (res.code === 20000) {
        if (res.data.url) {
          const popupCheck: any = window.open(res.data.url);
          if ((!popupCheck || popupCheck.closed || typeof popupCheck.closed === 'undefined') && !this.isApp) {
            this.$q.notify('팝업이 차단되어 있습니다.\n 팝업차단을 해제하신 뒤 다시 시도하여 주십시오.');
          }
        } else {
          this.$router.push({name: 'DepositResult', params: { 'reservationId': this.$route.params.reservationId }});
        }
      } else if (res.code === 40300) {
        this.$router.push({name: 'DepositResult', params: { 'reservationId': this.$route.params.reservationId }});
      }
    })
  }

  public openPopup(type: string) {
    this.privacyType = type;
    this.$modal.show('privacyPopup');
  }
  @Watch('selectLang')
  public selectLangUpdate() {
    if (this.selectLang !== 'ko') {
      this.cardData.pg_type = 'paypal';
    } else {
      this.cardData.pg_type = 'direct_pay';
    }
  }

  // 결제 금액 조회
  get totalPrice() {
    const data: any = {
      price: 0,
      price_usd: 0,
      date: ''
    };
    for (const pay of this.reservation.deposit) {
      if (!pay.paid_at) {
        data.price = pay.price;
        data.price_usd = pay.price_usd;
      }
    }
    return data;
  }

  // 버튼 활성화 / 비활성화
  get paymentValidate() {
    if (this.cardData.pg_type === 'direct_pay') {
      let isCardNo = false;
      let isCardData = false;

      this.cardNo.forEach((v) => {
        if (v || v.length === 4) isCardNo = true;
        else isCardNo = false;
      });

      if (this.cardData.card_type === 'C') {
        this.bizNo.forEach((v) => {
          if (v) isCardNo = true;
          else isCardNo = false;
        });
      }

      for (const data in this.cardData) {
        if (this.cardData[data]) {
          isCardData = true;
        } else if (data === 'card_number' || (this.cardData.card_type === 'C' && data === 'id_no')) {
          continue;
        } else {
          isCardData = false;
          break;
        }
      }

      if (this.agree.fee && this.agree.use && this.agree.thirdParty && !this.isLoading && isCardData && isCardNo) {
        return false;
      } else {
        return true;
      }
    } else {
      if (this.agree.fee && this.agree.use && this.agree.thirdParty && !this.isLoading) {
        if (this.cardData.pg_type === 'paymentwall') {
          const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
          if (!this.email.match(mailformat)) {
            return true;
          }
        }
        return false;
      } else {
        return true;
      }
    }
  }

  @Watch('cardData.card_type')
  public changeCardType() {
    this.cardNo = ['', '', '', ''];
    this.bizNo = ['', '', ''];
    this.cardData.card_pw = '';
    this.cardData.exp_month = '';
    this.cardData.exp_year = '';
    this.cardData.id_no = '';
  }
  // 인원 성인만 있는지 체크
  get onlyAdultCheck() {
    const re = this.reservation.people_types.filter((row: any, index: any) => {
      return index !== 0 && Number(row.party_size) !== 0;
    });
    if (re.length) {
      return true;
    }
    return false;
  }
  // 마지막 인원 타입
  get lastPersonKindIndex() {
    let type = '';
    this.reservation.people_types.filter((row: any) => {
      if (row.party_size !== 0) {
        type = row.name;
      }
    });
    if (type !== '') {
      return this.reservation.people_types.findIndex((row: any) => {
        return row.name === type;
      })
    }
    return 0;
  }
  get isApp() {
    if (this.$route.query._from) return true;
    else return false;
  }
}
